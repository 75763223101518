'use client';
import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from '@nextui-org/react';
import { FormButton } from '@/components/FormButton';
import { ParentButton } from '@/components/ParentButton';
import { Portal } from '@/lib/constants';

export default function LayoutHeader({ portal }: { portal: Portal }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const basicItems = [
    { label: 'Kids', href: '/kidsLogin' },
    { label: 'Causes', href: '/causes' },
    { label: 'About', href: '/about' },
  ];

  const publicItems = [{ label: 'Home', href: '/' }, { label: 'Parents', href: '/parents' }, ...basicItems];
  const parentsItems = [{ label: 'Home', href: '/parents' }, ...basicItems];
  const adminItems = [...parentsItems, { label: 'Admin', href: '/admin' }];

  const kidsItems = [
    { label: 'Home', href: '/kids' },
    { label: 'Story', href: '/kids/story' },
    { label: 'Donate', href: '/kids/donate' },
    { label: 'Library', href: '/kids/library' },
    { label: 'About', href: '/about' },
  ];

  const navItems =
    portal == Portal.Public
      ? publicItems
      : portal == Portal.Admin
        ? adminItems
        : portal == Portal.Parents
          ? parentsItems
          : kidsItems;

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      className="bg-old-lace backdrop-blur-none backdrop-saturate-100 max-w-full px-4 shadow sticky top-0 z-20"
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} className="sm:hidden" />
        <NavbarBrand>
          <Link href="/" className="mt-3">
            <img src="/static/CHCLogo.svg" width={80} />
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        {navItems.map((item, index) => (
          <NavbarItem key={`${item}-${index}`}>
            <Link className="uppercase font-bold font-titillium-web text-black" href={item.href}>
              {item.label}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>
      <NavbarContent justify="end">
        {portal == Portal.Public ? (
          <>
            <NavbarItem>
              <Link
                href="/parents"
                className="p-1 border-3 border-black bg-dark-slate-blue min-w-max place-self-center font-titillium-web text-old-lace text-xl text-center font-bold hover:text-black hover:bg-light-steel-blue disabled:opacity-50"
              >
                Log In
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link
                href="/signup"
                className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web  text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
              >
                Sign Up
              </Link>
            </NavbarItem>
          </>
        ) : (
          <NavbarItem>
            <Link
              href="/logout"
              className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web  text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
            >
              Log Out
            </Link>
          </NavbarItem>
        )}
      </NavbarContent>
      <NavbarMenu>
        {navItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link className="w-full uppercase font-bold font-titillium-web text-black" href={item.href} size="lg">
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
