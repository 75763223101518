// app/providers.tsx
'use client';

import { Portal } from '@/lib/constants';
import { PortalContext } from '@/lib/context';
import { NextUIProvider } from '@nextui-org/react';
import { SessionProvider } from 'next-auth/react';

export function Providers({ portal, children }: { portal: Portal; children: React.ReactNode }) {
  return (
    <PortalContext.Provider value={portal}>
      <SessionProvider>
        <NextUIProvider>{children}</NextUIProvider>
      </SessionProvider>
    </PortalContext.Provider>
  );
}
